import React from 'react';
import './Footer.css';
import logo from './logo1.png';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="footer-logo" />
      </div>
      <div className="container1">
        <ul>
          <li className="bold">Contact Info</li>
          <li>Address: Your address goes here, your demo address.</li>
          <li>Phone: +91 9820414796</li>
          <li>Email: sumitkumarmaurya151@gmail.com</li>
        </ul>
      </div>
      <div className="container2">
        <ul>
          <li className="bold">Service</li>
          <li>Training</li>
          <li>Coaching</li>
          <li>Consulting</li>
        </ul>
      </div>
      <div className="container3">
        <ul>
          <li className="bold">Important Links</li>
          <li>View Project</li>
          <li>Contact Us</li>
          <li>Testimonial</li>
          <li>Properties</li>
          <li>Support</li>
        </ul>
      </div>
      <div className="footer-social">
        <ul>
          <li className="bold">Social Handle</li>
          <li><a href="https://facebook.com"><FaFacebook /> <span>Facebook</span></a></li>
          <li><a href="https://twitter.com"><FaTwitter /> <span>Twitter</span></a></li>
          <li><a href="https://instagram.com"><FaInstagram /> <span>Instagram</span></a></li>
          
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
