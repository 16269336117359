import React, { useState } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import logo from './logo1.png';
import './App.css';

import Home from './components/Home';
import About from './components/About';
import LatestCauses from './components/LatestCauses';
import SocialEvents from './components/SocialEvents';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Donate from './components/Donate';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenuAndScrollToTop = () => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+9901010000888';
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:sumitmaurya@gmail.com';
  };

  return (
    <Router>
      <div className="container">
        <header className="top-header">
          <div className="contact-info">
            <span className="phone-text">Phone: +99 (0) 101 0000 888</span>
            <span className="email-text">Email: sumitmaurya@gmail.com</span>
          </div>
          <div className="social-icons">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <i className="fas fa-phone" onClick={handlePhoneClick}></i>
            <i className="fas fa-envelope" onClick={handleEmailClick}></i>
          </div>
        </header>

        <nav className="navbar">
          <img src={logo} alt="Charity" className="logo" />
          <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
            <ul>
              <li><Link to="/" onClick={closeMenuAndScrollToTop}>Home</Link></li>
              <li><Link to="/about" onClick={closeMenuAndScrollToTop}>About</Link></li>
              <li><Link to="/blog" onClick={closeMenuAndScrollToTop}>Blog</Link></li>
              <li><Link to="/contact" onClick={closeMenuAndScrollToTop}>Contact</Link></li>
            </ul>
          </div>
          <Link to="/donate">
            <button className="donate-button">Donate</button>
          </Link>
          <div className="hamburger" onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/latest-causes" element={<LatestCauses />} />
          <Route path="/social-events" element={<SocialEvents />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
