import React from "react";
import "./Home.css"; // Import custom CSS for animations and styles
import { Link } from "react-router-dom";
import kid from "./kid.jpeg";
import water from "./water.jpeg";
import health from "./health.jpeg";
import nature from "./nature.jpeg";
import about from './asset/kidgroup.jpg';
// import back from './asset/back.jpg';
import Footer from "./Footer";

function Home() {
  return (
    <>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-text">
          <h1>Our Helping To The World.</h1>
          <p>
            This foundation has embarked on a path of philanthropy, humanitarian
            efforts, and sustainable development. Join us in making a difference,
            and together we can build a brighter, healthier, and more
            sustainable future.
          </p>
          <div className="hero-buttons">
            <Link to="/donate">
              <button className="donate-button">Donate</button>
            </Link>
            <a href="tel:+12132541" className="call-us">
              <i className="fas fa-phone-alt"></i> +91 9820414796
            </a>
          </div>
        </div>
        <div className="hero-image">
          <img src={kid} alt="Helping Hand" />
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2>We Are On A Mission To Help The Helpless</h2>
        <div className="services-container">
  <div className="service-card">
    <div className="icon-circle">
      <i className="fas fa-tint"></i>
    </div>
    <h3>Clean Water</h3>
    <p>We provide clean water services to those in need.</p>
    <img src={water} alt="Clean Water" className="service-logo" />
  </div>
  <div className="service-card highlighted-card">
    <div className="icon-circle">
      <i className="fas fa-heartbeat"></i>
    </div>

    <h3>Health Care</h3>
    <p>We support healthcare initiatives for better life expectancy.</p>
    <img src={health} alt="Health Care" className="service-logo" />
  </div>
  <div className="service-card">
    <div className="icon-circle">
      <i className="fas fa-leaf"></i>
    </div>
    <h3>Environment</h3>
    <p>Our commitment to sustainability protects future generations.</p>
    <img src={nature} alt="Environment" className="service-logo" />
  </div>
  <div className="service-card">
    <div className="icon-circle">
      <i className="fas fa-book"></i>
    </div>
    <h3>Education Support</h3>
    <p>We promote education opportunities for underprivileged children.</p>
    <img src={kid} alt="Education Support" className="service-logo" />
  </div>
</div>

      </section>
      <section className="mission-section">
        <div className="mission-image">
          <img src={about} alt="Helping" />
        </div>
        <div className="mission-text">
          <h2>About Our Foundation</h2>
          <h3>We Are In A Mission To Help The Helpless</h3>
          <p>
            This foundation has embarked on a path of philanthropy, humanitarian
            efforts, and sustainable development. This historical account
            chronicles the evolution of the Way of True Life Foundation from its
            humble beginnings, highlighting its key achievements and
            contributions to humanity.
          </p>
          <button className="about-button">About Us</button>
        </div>
      </section>
     <section className="gallery-section">
        <h2>Our Work In Action</h2>
        <div className="gallery-container">
          <img src={water} alt="Clean Water" className="gallery-image" />
          <img src={health} alt="Health Care" className="gallery-image" />
          <img src={nature} alt="Environment" className="gallery-image" />
          <img src={kid} alt="Education Support" className="gallery-image" />
          <img src={kid} alt="Education Support" className="gallery-image" />
          <img src={water} alt="Clean Water" className="gallery-image" />
          <img src={health} alt="Health Care" className="gallery-image" />
          <img src={nature} alt="Environment" className="gallery-image" />
        </div>
      </section>
      <div className="counter-section">
        <div className="counter-item">
          <span className="counter-number">620+</span>
          <span className="counter-label">Donation</span>
        </div>
        <div className="counter-item">
          <span className="counter-number">80+</span>
          <span className="counter-label">Fund Raised</span>
        </div>
        <div className="counter-item">
          <span className="counter-number">256+</span>
          <span className="counter-label">Donation</span>
        </div>
        <div className="counter-item">
          <span className="counter-number">256+</span>
          <span className="counter-label">Donation</span>
        </div>
      </div>
      <Footer />
      <div className="copyright_section">
        <div className="copyright_text">
          Copyright 2024 All Rights Reserved By{" "}
          <a href="/" className="footer-link">
            Foundation.in
          </a>
        </div>
      </div>
    </>
  );
}

export default Home;
