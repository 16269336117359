import React from 'react';
import './Blog.css';
import { FaCalendarAlt, FaComments } from 'react-icons/fa';
import Footer from './Footer';

// Import images
import post1 from './asset/post1.jpg';
import post2 from './asset/post2.jpg';
import post3 from './asset/post3.jpg';
import post4 from './asset/post4.jpg';
import post5 from './asset/post5.jpg';
import post6 from './asset/post6.jpg';

const Blog = () => {
   const blogPosts = [
      { id: 1, title: "Google inks pact for new 35-storey office", date: "Nov 10, 2024", summary: "That dominion stars lights dominion divide years for fourth have don't stars is that he earth it first without heaven in place seed it second morning saying.", image: post1, link: "https://en.wikipedia.org/wiki/Google" },
      { id: 2, title: "Building UI with Animations", date: "Nov 5, 2024", summary: "Learn how animations can elevate UI and improve user engagement with examples.", image: post2, link: "https://en.wikipedia.org/wiki/Animation" },
      { id: 3, title: "AI in Healthcare", date: "Nov 3, 2024", summary: "Discover the latest advancements in AI technology in the healthcare industry.", image: post3, link: "https://en.wikipedia.org/wiki/Artificial_intelligence_in_healthcare" },
      { id: 4, title: "Sustainable Building Practices", date: "Nov 1, 2024", summary: "Explore sustainable methods in architecture and urban planning.", image: post4, link: "https://en.wikipedia.org/wiki/Sustainable_architecture" },
      { id: 5, title: "AI in Healthcare", date: "Nov 3, 2024", summary: "Discover the latest advancements in AI technology in the healthcare industry.", image: post5, link: "https://en.wikipedia.org/wiki/Artificial_intelligence_in_healthcare" },
      { id: 6, title: "Sustainable Building Practices", date: "Nov 1, 2024", summary: "Explore sustainable methods in architecture and urban planning.", image: post6, link: "https://en.wikipedia.org/wiki/Sustainable_architecture" },
   ];

   return (
      <>
         <div className="blog-page">
            <header className="blog-header">
               <h1>Our Blog</h1>
               <p>Stay updated with the latest insights and trends.</p>
            </header>
            <div className="blog-grid">
               {blogPosts.map(post => (
                  <div className="blog-card" key={post.id}>
                     <img src={post.image} alt={post.title} className="blog-image" />
                     <div className="blog-content">
                        <h3>{post.title}</h3>
                        <div className="blog-meta">
                           <FaCalendarAlt /> {post.date} <FaComments /> 5 Comments
                        </div>
                        <p>{post.summary}</p>
                        <a href={post.link} target="_blank" rel="noopener noreferrer" className="read-more-btn">Read More</a>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <Footer />
         <div className="copyright_section">
            <div className="copyright_text">
               Copyright 2024 All Rights Reserved By{" "}
               <a href="/" className="footer-link">
                  Foundation.in
               </a>
            </div>
         </div>
      </>
   );
};

export default Blog;
