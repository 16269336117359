import React from 'react';
import Footer from "./Footer";
import kid from "./kid.jpeg";
import water from "./water.jpeg";
import nature from "./nature.jpeg";
import './SocialEvents.css'; // Add a CSS file for custom styles

function SocialEvents() {
  return (
    <div className="social-events">
      <h2 className="section-heading">Social Events</h2>
      <div className="counter-section">
        {[
          { count: '620+', label: 'Donations' },
          { count: '80+', label: 'Funds Raised' },
          { count: '256+', label: 'Projects' },
          { count: '256+', label: 'Volunteers' },
        ].map((item, index) => (
          <div key={index} className="counter-item">
            <span className="counter-number">{item.count}</span>
            <span className="counter-label">{item.label}</span>
          </div>
        ))}
      </div>
      
      <section className="featured-job-area section-padding30 section-bg2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12">
              <div className="section-tittle text-center mb-80">
                <h2>We arrange many social events for charity donations</h2>
              </div>
            </div>
          </div>
          
          <div className="row justify-content-center">
            {[{
                img: kid,
                title: 'Donation is Hope',
                time: '8:30 - 9:30am',
                date: '18.01.2021',
                location: 'Mumbai'
              },
              {
                img: nature,
                title: 'A hand for Children',
                time: '8:30 - 9:30am',
                date: '18.01.2021',
                location: 'Mumbai'
              },
              {
                img: water,
                title: 'Help for Children',
                time: '8:30 - 9:30am',
                date: '18.01.2021',
                location: 'Mumbai'
              }
            ].map((event, index) => (
              <div key={index} className="col-lg-9 col-md-12">
                <div className="single-job-items mb-30 shadow-hover">
                  <div className="job-items">
                    <div className="company-img">
                      <a href="/">
                        <img src={event.img} alt="Helping" className="event-img"/>
                      </a>
                    </div>
                    <div className="job-tittle">
                      <a href="/">
                        <h4>{event.title}</h4>
                      </a>
                      <ul className="event-details">
                        <li><i className="far fa-clock"></i> {event.time}</li>
                        <li><i className="fas fa-sort-amount-down"></i> {event.date}</li>
                        <li><i className="fas fa-map-marker-alt"></i> {event.location}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
      <div className="copyright_section">
        <div className="copyright_text">
          © 2024 All Rights Reserved By <a href="/" className="footer-link">Foundation.in</a>
        </div>
      </div>
    </div>
  );
}

export default SocialEvents;
