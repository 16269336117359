import React from 'react';
import qrCode from './qrCode.jpg';
import './Donate.css';
import Footer from "./Footer";

const Donate = () => {
  
  const handleShare = async () => {
    if (navigator.share && navigator.canShare) {
      try {
        // Fetch the QR code image as a Blob
        const response = await fetch(qrCode);
        const blob = await response.blob();
        const file = new File([blob], 'qrCode.jpg', { type: blob.type });

        if (navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: 'Support Our Mission',
            text: 'Scan the QR code to donate and contribute to our efforts in making a difference.',
            files: [file],
          });
          console.log('Thanks for sharing!');
        } else {
          console.warn('File sharing is not supported on this device.');
        }
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert("Sharing is not supported in this browser.");
    }
  };

  return (
    <>
      <div className="donate-container">
        <div className="donate-content">
          <h1 className="donate-title">Support Our Mission</h1>
          <p className="donate-text">
            Scan the QR code to donate and contribute to our efforts in making a difference.
          </p>
          <div className="qr-code-container">
            <img src={qrCode} alt="QR Code for Donation" className="qr-code" />
          </div>
          
          <p className="donate-footer-text">
            Every small contribution helps us bring positive change. Thank you for your support!
          </p>
          <button className="share-button" onClick={handleShare}>Share</button>
        </div>
      </div>
      
      <Footer />

      <div className="copyright_section">
        <div className="copyright_text">
          Copyright 2024 All Rights Reserved By{" "}
          <a href="/" className="footer-link">
            Foundation.in
          </a>
        </div>
      </div>
    </>
  );
};

export default Donate;
