import React from 'react';
import Footer from "./Footer";
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Contact() {

  // Redirection function
const handleCardClick = (type) => {
  switch (type) {
    case 'address':
      window.location.href = "https://www.google.com/maps?q=400101+Kandivali,+Mumbai,+India";
      break;
    case 'phone':
      window.location.href = "tel:+919820414796";
      break;
    case 'email':
      window.location.href = "mailto:sumit@gmail.com";
      break;
    case 'whatsapp':
      window.location.href = "https://wa.me/919820414796"; // WhatsApp chat link
      break;
    default:
      break;
  }
};


  return (
    <>
      <div className="contact-container">
        <h2 className="contact-title">Get in Touch</h2>
        <p className="contact-description">
          We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, reach out to us anytime.
        </p>
        
        <div className="contact-cards">
          <div className="contact-card" onClick={() => handleCardClick('address')}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <h3>Our Address</h3>
            <p>400101 Kandivali, Mumbai, India</p>
          </div>
          <div className="contact-card" onClick={() => handleCardClick('phone')}>
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <h3>Phone</h3>
            <p>+91 9820414796</p>
          </div>
          <div className="contact-card" onClick={() => handleCardClick('email')}>
            <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
            <h3>Email</h3>
            <p>sumit@gmail.com</p>
          </div>
          <div className="contact-card" onClick={() => handleCardClick('whatsapp')}>
            <FontAwesomeIcon icon={faWhatsapp} className="contact-icon" />
            <h3>WhatsApp</h3>
            <p>+91 9820414796</p>
          </div>
        </div>
        
        <form className="contact-form">
          <input type="text" placeholder="Your Name" className="contact-input" required />
          <input type="email" placeholder="Your Email" className="contact-input" required />
          <textarea placeholder="Your Message" className="contact-input message-input" required></textarea>
          <button type="submit" className="contact-submit">Send Message</button>
        </form>
      </div>
      
      <Footer />
      
      <div className="copyright_section">
        <div className="copyright_text">
          Copyright 2024 All Rights Reserved By{" "}
          <a href="/" className="footer-link">Foundation.in</a>
        </div>
      </div>
    </>
  );
}

export default Contact;
