// components/LatestCauses.js
import React from 'react';
import Footer from "./Footer";
import kid from "./kid.jpeg";
import water from "./water.jpeg";
import health from "./health.jpeg";
import back from './asset/back.jpg';
import './LatestCauses.css';
function LatestCauses() {
  return (
    <div>
      <h2>Latest Causes</h2>
      <section className="our-cases-area section-padding30">
        <h2 className="section-title">
          Explore Our Latest Causes That We Work On
        </h2>
        <div className="cases-container">
          {[
            {
              title: "Ensure Education For Every Poor Child",
              progress: 70,
              raised: 20000,
              goal: 35000,
              img: kid,
            },
            {
              title: "Providing Healthy Food For The Children",
              progress: 25,
              raised: 5000,
              goal: 20000,
              img: health,
            },
            {
              title: "Supply Drinking Water For The People",
              progress: 50,
              raised: 10000,
              goal: 20000,
              img: water,
            },
            {
              title: "Providing Healthy Food For The Children",
              progress: 25,
              raised: 5000,
              goal: 20000,
              img: health,
            },
            {
              title: "Ensure Education For Every Poor Child",
              progress: 70,
              raised: 20000,
              goal: 35000,
              img: back,
            },
            {
              title: "Supply Drinking Water For The People",
              progress: 50,
              raised: 10000,
              goal: 20000,
              img: water,
            },
            {
              title: "Ensure Education For Every Poor Child",
              progress: 70,
              raised: 20000,
              goal: 35000,
              img: kid,
            },
            {
              title: "Supply Drinking Water For The People",
              progress: 50,
              raised: 10000,
              goal: 20000,
              img: water,
            },
          ].map((cause, index) => (
            <div key={index} className="cause-card">
              <img src={cause.img} alt={cause.title} className="cause-img" />
              <h3>{cause.title}</h3>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${cause.progress}%` }}
                ></div>
              </div>
              <p>
                Raised: ${cause.raised.toLocaleString()} | Goal: $
                {cause.goal.toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      </section>
      <Footer />
      <div className="copyright_section">
        <div className="copyright_text">
          Copyright 2024 All Rights Reserved By{" "}
          <a href="/" className="footer-link">
            Foundation.in
          </a>
        </div>
      </div>
    </div>
  );
}

export default LatestCauses;
